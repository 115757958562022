import { useState } from "react";
import $api from "../service/api-service";
import { useDispatch } from "react-redux";
import { ToastActionCreator } from "../store/toast";

const CreateTransfertsPage = () => {
    const [senderStudentList, setSenderList] = useState([]);
    const [recepientStudentList, setRecepientList] = useState([]);
    const dispatch = useDispatch()

    const [transfertsData, setTransfertsData] = useState({
        sender: "", 
        recipient: "", 
        amount: 0,
    })

    const [studentNames, setStudentsNames] = useState({
        sender: '',
        recipient: ''
    })

    const submitHandler = (evt) => {
        evt.preventDefault();
        $api.post('/balance/create-transfert',
            transfertsData
        ).then(({data})=>{
            console.log(data)
            setTransfertsData({
                sender: "", 
                recipient: "", 
                amount: 0,
            })
            setStudentsNames({
                sender: '',
                recipient: ''
            })
            dispatch(ToastActionCreator(`${data.outcome.info} на сумму ${data.outcome.amount} руб`));
        })
    }

    const nameChangeHandeler = ({target}) => {
        setStudentsNames(p=>({...p, [target.name]: target.value}));
        if(target.value.length > 2) {
            $api.get('/student/search/'+target.value)
                .then(({data})=>{
                    if(target.name === 'sender') {
                        return setSenderList(data)
                    } 
                    setRecepientList(data)
                })
        }
    }

    const searchResultClickHandler = ({target}) => {
        setTransfertsData(p=>({...p, 
            [target.dataset.field]: target.dataset.contract,
        }));
        setStudentsNames(p=>({
            ...p, [target.dataset.field]: target.dataset.name
        }));

        if(target.dataset.field === 'sender') {
            return setSenderList([])
            } 
        setRecepientList([])
    }

    const changeHandler = ({target}) => {
        setTransfertsData(p=>({...p, [target.name]: target.value}));
    }


    return(
        <div className="container">
            <h1>Перевод суммы у студента</h1>
            <form onSubmit={submitHandler}>
                <div className="creating-page__input-wrap">
                    <input value={studentNames.sender} type="text" name="sender" placeholder="Отправитель" onChange={nameChangeHandeler}/>
                    <span>{transfertsData.sender}</span>
                </div>
                <div className="searching-student__wrap payment--page__searching">
                    {senderStudentList.map(el=>(
                        <div key={el._id} 
                            onClick={searchResultClickHandler} 
                            className="search-result__item"
                            data-contract={el.contract} 
                            data-name={el.name} 
                            data-field="sender"
                        >
                            {el.name}</div>
                    ))}
                </div>
                <div className="creating-page__input-wrap">
                    <input value={studentNames.recipient} type="text" name="recipient" placeholder="Получатель" onChange={nameChangeHandeler}/>
                    <span>{transfertsData.recipient}</span>
                </div>
                <div className="searching-student__wrap payment--page__searching">
                    {recepientStudentList.map(el=>(
                        <div key={el._id} 
                            onClick={searchResultClickHandler} 
                            className="search-result__item"
                            data-contract={el.contract} 
                            data-name={el.name} 
                            data-field="recipient"
                        >
                            {el.name}</div>
                    ))}
                </div>
                <div className="creating-page__input-wrap">
                    <input value={transfertsData.amount} type="number" name="amount" placeholder="Сумма" onChange={changeHandler}/>
                </div>
                <div className="creating-page__input-wrap">
                    {(transfertsData.sender && transfertsData.recipient )&& <button className="yc_button" type='submit'>Создать</button>}
                </div> 
            </form>
        </div>
    )
}

export default CreateTransfertsPage;